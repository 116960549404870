import { withRouter, useHistory, useLocation, Link } from "react-router-dom";
import "firebase/auth"
import GoogleIcon from "./google.png"
import "./LoginPage.scss";
import { useAuth } from "../../Hooks/useAuth";


const LoginPage = () => {
    let history = useHistory();
    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/" } };
    // const authManager  = useAuth();
    // authManager.basicLog()
    const { currentUser, setCurrentUser, handleGoogleSignIn, signOut, basicLog } = useAuth();
    // const { setUserPromo, userPromo } = usePromo()

    // const handleGoogleSignIn = () => {
    //     var provider = new firebase.auth.GoogleAuthProvider();
    //     firebase.auth().signInWithPopup(provider).then(function (result) {
    //         const { displayName, email, uid, photoURL } = result.user;
    //         const signedInUser = { name: displayName, email, userId: uid, photoURL }
    //     }).catch(function (error) {
    //         const errorMessage = error.message;
    //         alert(errorMessage);
    //     });
    // }

    // const signOut = () => {
    //     firebase.auth().signOut().then(res => {
    //         sessionStorage.removeItem("user");
    //         history.replace("/login")
    //     }).catch(err => {
    //         alert(err);
    //     })
    // }
    const signInHandler = () => {
        return handleGoogleSignIn()
            .then((data) => {
                console.log("loggedin", data);
                history.replace(from)
            })
    }

    return (
        <>
            <div className="container">
                <div className="row py-5">
                    <div className="col-md-6 my-4 offset-md-3  text-center">
                        <div className="p-5 login-layout">
                            <h2 className="text-center">{currentUser && currentUser.name}</h2>
                            <Link to="/add-subscription"><h2 className="text-center">Go to your profile</h2> </Link>
                            <h3 className="text-center mb-3 mt-4">
                                Login Here
                            </h3>
                            <button onClick={signInHandler} className="btn custom-auth-btn btn-block py-3 mb-3 mt-3">
                                <img src={GoogleIcon} alt="" />
                                Continue with Google
                            </button>
                            <p className="text-center mb-5">
                                Don't have an account?{" "}
                                <Link href="/login">Create an account</Link>
                            </p>
                            <button onClick={signOut} className="btn btn-danger">Sign out</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="container">
                <div className="row py-5">
                    <div className="col-md-6 my-4 offset-md-3  text-center">
                        <a href="/">
                            <p></p>
                        </a>
                        <div className="p-5 login-container">
                            <Link to="/"><h2 className="text-center">Go to your profile</h2> </Link>
                            <h3 className="text-center mb-3 mt-4">
                                Login Here
                            </h3>
                            <button onClick={handleGoogleSignIn} className="btn custom-auth-btn btn-block py-3 mb-3 mt-3">
                                <img src={GoogleIcon} alt="" />
                                Continue with Google
                            </button>
                            <p className="text-center mb-5">
                                Don't have an account?{" "}
                                <a href="/login">Create an account</a>
                            </p>
                            <button onClick={signOut} className="btn btn-danger">Sign out</button>
                        </div>
                    </div>
                </div>
            </div> */}

        </>
    );
};

export default withRouter(LoginPage);
