import { Layout, Menu } from "antd";
import { UploadOutlined, InsertRowLeftOutlined, BookOutlined, FormOutlined, CalendarOutlined, RadarChartOutlined, EyeInvisibleOutlined, HomeOutlined, FileOutlined, TeamOutlined, UserAddOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import './AntdMenu.scss';
import { useAuth } from "../../Hooks/useAuth";
import firebase from 'firebase/app'
import "firebase/auth";
import "firebase/firestore";

const { Sider, } = Layout;
const { SubMenu } = Menu;


const AntdMenu = ({ collapsed, setCollapsed }) => {
    const { hasAccess } = useAuth()
    const showMenu = hasAccess;

    const onLogOut = () => {
        firebase.auth().signOut().then(res => {
            sessionStorage.removeItem("token")
        }).catch(err => {
            alert(err);
        })
    }
    return (
        <>
            <Sider title="Shubhankar"
                style={{
                    height: '100vh',
                    position: 'fixed',
                    zIndex: '20'
                }}
                breakpoint="sm"
                collapsedWidth="0">
                <Menu theme="dark" mode="inline" defaultSelectedKeys={[showMenu ? '1' : '5']}>
                    <h5 className="text-white text-center mt-4 mb-4">Shubhankar <br />Consulting </h5>
                    {showMenu &&
                        <Menu.Item key="1" icon={<HomeOutlined />}>
                            <Link className=" mt-3" to="/">Home</Link>
                        </Menu.Item>}
                    {/* 12, 13 -- Blogs */}
                    {/* {showMenu && <SubMenu key="services" icon={<UserOutlined />} title="Services">
                        <Menu.Item key="2" icon={<UserOutlined />}>
                            <Link className="mt-2" to="/services">All Services</Link>
                        </Menu.Item>
                        <Menu.Item key="3" icon={<VideoCameraOutlined />}>
                            <Link className="mt-2" to="/service/add">Add Service</Link>
                        </Menu.Item>
                    </SubMenu>} */}
                    {/* 2, 3 -- Blogs */}
                    {showMenu && <SubMenu key="sub1" icon={<BookOutlined />} title="Blogs">
                        <Menu.Item key="2" icon={<BookOutlined />}>
                            <Link className="mt-2" to="/blogs">All blogs</Link>
                        </Menu.Item>
                        <Menu.Item key="3" icon={<FormOutlined />}>
                            <Link className="mt-2" to="/blog/add">Add blog</Link>
                        </Menu.Item>
                    </SubMenu>}
                    {/* 4, 5 -- Insights */}
                    {showMenu && <SubMenu key="sub2" icon={<FileOutlined />} title="Insights">
                        <Menu.Item key="4" icon={<FileOutlined />}>
                            <Link className="mt-2" to="/insights">All Insights</Link>
                        </Menu.Item>
                        <Menu.Item key="5" icon={<FormOutlined />}>
                            <Link className="mt-2" to="/insight/add">Add Insight</Link>
                        </Menu.Item>
                    </SubMenu>}
                    {/* 6, 7 -- Insights */}
                    {showMenu && <SubMenu key="sub3" icon={<InsertRowLeftOutlined />} title="News">
                        <Menu.Item key="6" icon={<InsertRowLeftOutlined />}>
                            <Link className="mt-2" to="/news">All News</Link>
                        </Menu.Item>
                        <Menu.Item key="7" icon={<FormOutlined />}>
                            <Link className="mt-2" to="/news/add">Add News</Link>
                        </Menu.Item>
                    </SubMenu>}

                    {/* 8, 9 -- Insights */}
                    {showMenu && <SubMenu key="sub4" icon={<RadarChartOutlined />} title="Case Studies">
                        <Menu.Item key="8" icon={<RadarChartOutlined />}>
                            <Link className="mt-2" to="/case-studies">All Case Studies</Link>
                        </Menu.Item>
                        <Menu.Item key="9" icon={<FormOutlined />}>
                            <Link className="mt-2" to="/case-studies/add">Add Case Study</Link>
                        </Menu.Item>
                    </SubMenu>}
                    {/* 10, 11 -- Writers */}
                    {showMenu && <SubMenu key="sub5" icon={<TeamOutlined />} title="Writers">
                        <Menu.Item key="10" icon={<TeamOutlined />}>
                            <Link className="mt-2" to="/writers">All Writers</Link>
                        </Menu.Item>
                        <Menu.Item key="11" icon={<UserAddOutlined />}>
                            <Link className="mt-2" to="/writers/add">Add Writer</Link>
                        </Menu.Item>
                    </SubMenu>}
                    {showMenu &&
                        <Menu.Item key="100" icon={<CalendarOutlined />}>
                            <Link className=" mt-3" to="/appointments">Appointments</Link>
                        </Menu.Item>}
                    {showMenu ?
                        <div className="text-center">
                            <button onClick={onLogOut} className="btn btn-primary btn-sm  mt-3">Log out</button>
                        </div> :
                        <Menu.Item key="15" icon={<EyeInvisibleOutlined />}>
                            <Link className=" mt-3" to="/login">Login</Link>
                        </Menu.Item>}
                </Menu>
            </Sider>
            <div className="d-none d-md-block">
                <Sider title="Shubhankar"
                    style={{
                        height: '100vh',

                    }}
                    breakpoint="sm"
                    collapsedWidth="0">
                    <Menu theme="dark" mode="inline" defaultSelectedKeys={[showMenu ? '1' : '5']}>
                        <h4 className="text-white text-center mt-3">Shubhankar <br />Consulting </h4>
                        <hr />
                        {showMenu &&
                            <Menu.Item key="1" icon={<UploadOutlined />}>
                                <Link className="mt-2" to="/">Home</Link>
                            </Menu.Item>}
                        {showMenu &&
                            <Menu.Item key="1" icon={<UploadOutlined />}>
                                <Link className="mt-2" to="/">Home</Link>
                            </Menu.Item>}
                        {showMenu &&
                            <Menu.Item key="1" icon={<UploadOutlined />}>
                                <Link className="mt-2" to="/">Home</Link>
                            </Menu.Item>}
                        {showMenu &&
                            <Menu.Item key="1" icon={<UploadOutlined />}>
                                <Link className="mt-2" to="/">Home</Link>
                            </Menu.Item>}
                    </Menu>
                </Sider>
            </div>
        </>
    );
};

export default AntdMenu;