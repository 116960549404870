const firebaseConfig = {
  apiKey: "AIzaSyCHqH9oRR-5VouhEC6-i5i47Q_sDi9sHm0",
  authDomain: "shubhankar-consulting.firebaseapp.com",
  projectId: "shubhankar-consulting",
  storageBucket: "shubhankar-consulting.appspot.com",
  messagingSenderId: "83373214386",
  appId: "1:83373214386:web:330669fcf60552bef757ab"
};
// const firebaseConfig = {
//   apiKey: "AIzaSyBDD5PL9j3uAUlhXZYcvvlwmV1DurNbqaI",
//   authDomain: "shubhankar-consultancy.firebaseapp.com",
//   projectId: "shubhankar-consultancy",
//   storageBucket: "shubhankar-consultancy.appspot.com",
//   messagingSenderId: "543061105507",
//   appId: "1:543061105507:web:655e6ffec59e96f4de1156"
// };
export default firebaseConfig