import { Route, Switch } from "react-router-dom"
import AntdLayout from '../Components/AntdLayout/AntdLayout';
import { useAuth } from "../Hooks/useAuth";
import AppointmentsPage from "./Appointments/AppointmentsPage";
import AddNewBlog from "./Blogs/AddNewBlog";
// import EditBlog from "./Blogs/EditBlog";
import PageBlogs from "./Blogs/PageBlogs";
import AddNewCase from "./CaseStudies/AddNewCase";
// import EditCase from "./CaseStudies/EditCase";
import PageCaseStudies from "./CaseStudies/PageCaseStudies";
import AddNewInsight from "./Insights/AddNewInsight";
import EditInsight from "./Insights/EditInsight";
import PageInsights from "./Insights/PageInsights";
import LoginPage from "./LoginPage/LoginPage";
import AddNewNews from "./News/AddNewNews";
// import EditNews from "./News/EditNews";
import PageNews from "./News/PageNews";
// import AllServices from "./Services/AllServices";
import TestPage from "./TestPage";
import AddWriter from "./Writers/AddWriter";
import AllWriters from "./Writers/AllWriters";
import EditWriter from "./Writers/EditWriter";

const Main = () => {
    const { hasAccess } = useAuth()
    const giveAccess = hasAccess;
    return (
        <>
            <AntdLayout>
                <Switch>
                    {giveAccess && <Route exact path="/">
                        <TestPage />
                    </Route>}
                    {/* services */}
                    {/* {giveAccess &&<Route exact path="/services">
                        <AllServices/>
                    </Route>} */}
                    {/* login */}
                    <Route exact path="/login">
                        <LoginPage />
                    </Route>

                    {/* blogs */}
                    {giveAccess && <Route exact path="/blogs">
                        <PageBlogs />
                    </Route>}
                    {giveAccess && <Route exact path="/blog/add">
                        <AddNewBlog />
                    </Route>}
                    {/* {giveAccess && <Route exact path="/blog/edit/:id">
                        <EditBlog />
                    </Route>} */}

                    {/* insights */}
                    {giveAccess && <Route exact path="/insights">
                        <PageInsights />
                    </Route>}
                    {giveAccess && <Route exact path="/insight/add">
                        <AddNewInsight />
                    </Route>}
                    {giveAccess && <Route exact path="/insight/edit/:id">
                        <EditInsight />
                    </Route>}

                    {/* news */}
                    {giveAccess && <Route exact path="/news">
                        <PageNews />
                    </Route>}
                    {giveAccess && <Route exact path="/news/add">
                        <AddNewNews />
                    </Route>}
                    {/* {giveAccess && <Route exact path="/news/edit/:id">
                        <EditNews />
                    </Route>} */}

                    {/* case */}
                    {giveAccess && <Route exact path="/case-studies">
                        <PageCaseStudies />
                    </Route>}
                    {giveAccess && <Route exact path="/case-studies/add">
                        <AddNewCase />
                    </Route>}
                    {/* {giveAccess && <Route exact path="/case-studies/edit/:id">
                        <EditCase />
                    </Route>} */}

                    {/* writers */}
                    {giveAccess && <Route exact path="/writers">
                        <AllWriters />
                    </Route>}
                    {giveAccess && <Route exact path="/writers/add">
                        <AddWriter />
                    </Route>}
                    {giveAccess && <Route exact path="/writers/edit/:writerId">
                        <EditWriter />
                    </Route>}

                    {/* bookings */}
                    {giveAccess && <Route exact path="/appointments">
                        <AppointmentsPage />
                    </Route>}
                </Switch>
            </AntdLayout>
        </>
    );
};

export default Main;