import React from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { appointmentsCollectionRef } from '../../firebase/fire-app';
import "./booking.scss";
import moment from 'moment';
import AppointmentDetails from './AppointmentDetails';
import { Avatar } from 'antd';

const AppointmentsPage = () => {
    const [bookings, loadBooking, errBooking] = useCollectionData(appointmentsCollectionRef.orderBy("appointmentTime", "desc"), { idField: "id" });

    return (
        <>
            <h4 className="font-weight-bold">All Appointments </h4>
            <section className="p-4">
                {bookings && bookings.map((item, index) => <AppointmentCard index={index} data={item} user={item.user} key={item.id} />)}
            </section>
        </>
    );
};

export default AppointmentsPage;

export const AppointmentCard = ({ data, user, index }) => {
    console.log(user)
    const startTime = new Date(data.appointmentTime.seconds * 1000);
    var check = moment(startTime);
    var month = check.format('MMMM');
    var day = check.format('D');
    var year = check.format('YYYY');
    var hour = check.format("HH")
    var minute = check.format("MM")
    return (
        <>
            {data && <div className="row booking-card mb-3">
                {user && <div className="col-md-3 d-flex align-items-center border-right-dark">
                    {/* <h5 className="font-bold pr-3 mb-0">
                    {index + 1}
                </h5> */}
                    <Avatar
                        style={{
                            backgroundColor: "#f56a00",
                            verticalAlign: 'middle',
                        }}
                        size="medium"
                        gap="1"
                    >
                        {index + 1}
                    </Avatar>
                    <div className="pl-2">
                        <h5 className="font-weight-bold mb-0">
                            {user && user.displayName}
                        </h5>
                        <p className="mb-0">{user.workPlace ? user.workPlace : user.eduInstitution} </p>
                        <p className="mb-0">({user.userCategory})</p>
                    </div>
                </div>}
                {user && <div className="col-md-3 border-right-dark">
                    <p className="mb-2 font-weight-bold">{user.email}</p>
                    {/* <p className="mb-0 font-weight-bold text-underline"><a href={`mailto:${user.email}`}>{user.email}</a></p> */}
                    <p className="mb-0 font-weight-bold text-underline"><a href={`tel:${user.phone}`}>{user.phone} </a> </p>
                </div>}
                <div className="col-md-3 border-right-dark">
                    <p className="mb-0"><span className="mb-0 font-bold">Topic: </span>{data.topic}</p>
                    <p className="mb-0"><span className="font-bold mb-0">Description: </span>{data.description}</p>
                </div>
                <div className="col-md-3">
                    <p className="mb-2 font-bold ">{`${month} ${day}, ${year} | ${hour}:${minute}`} </p>
                    <div className="d-flex justify-content-around">
                        {data && user && <AppointmentDetails appData={data} user={user} index={index} />}
                        <button className="btn-sm btn-dark btn">Approve</button>
                        <button className="btn-sm btn-dark btn">Finished</button>
                    </div>
                </div>
            </div>}
        </>
    )
}