import React from 'react';

const TestPage = () => {
    return (
        <>
            <div className="jumbotron text-center">
                <h2>Shubhankar Consulting</h2>
            </div>
            <div className="text-center h3">Dashboard</div>
        </>
    );
};

export default TestPage;